import React from "react";
import { Link } from "gatsby";
import posthog from "posthog-js";

import Layout from "../components/layout";

export default function PageNotFound() {
  posthog.capture("$404");
  return (
    <Layout>
      <main
        id="content"
        role="main"
        className="main sv-landing full-page-content"
      >
        <section className="overflow-hidden">
          <h2 className="md-h2 caps pl4 mt4">
            <div>Page not found</div>
          </h2>
          <div className="mb4 pl4 pr4 mt4">
            <p>
              <Link to="/">Go back to the home page.</Link>
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
}
